<template>
  <div class="modalPopup print_modal barcode_modal">
    <div class="modal_header">
      <h2 class="title">
        {{ checkPage }}
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body" v-if="checkPage == '발주식별표'">
      <ul>
        <li>
          <h6>
            구매처
          </h6>
          <p>
            {{ findInfoFromId(companys, printList.company_id).name }}
          </p>
        </li>
        <li>
          <h6>
            발주품목
          </h6>
          <p>
            {{ printList.items }}
          </p>
        </li>
        <li>
          <h6>
            입고 요청일
          </h6>
          <p>{{ printList.incoming_date }}</p>
        </li>
        <li>
          <h6>
            발주일
          </h6>
          <p>{{ printList.input_date }}</p>
        </li>
        <li>
          <h6>바코드</h6>
          <ul>
            <li>
              <p class="barcode">{{ `*${printList.barcode_num}*` }}</p>
            </li>
            <li>
              <p class="barcode_number">
                {{ $makeNumber(`${printList.barcode_num}`) }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="modal_body" v-else>
      <ul
        v-for="(item, index) in printList.filter(x => x.check_print)"
        :key="item.id"
      >
        <li>
          <h6>
            {{
              checkPage == '입고식별표'
                ? '원자재명'
                : '생산식별표'
                ? '제품명'
                : ''
            }}
          </h6>
          <p>
            {{
              checkPage == '입고식별표'
                ? item.material_name
                : '생산식별표'
                ? findInfoFromId(Product, item.product_id).name
                : ''
            }}<br />{{
              checkPage == '생산식별표' && systemCompany.expire_yn
                ? ` (${getExpireDate(item)}까지)`
                : ''
            }}
          </p>
        </li>
        <li>
          <h6>규격</h6>
          <p>
            {{
              checkPage == '입고식별표'
                ? item.material_standard
                : '생산식별표'
                ? item.product_standard
                : ''
            }}
          </p>
        </li>
        <li>
          <h6>수량</h6>
          <p>{{ `${$makeComma(item.quantity)}(${getUnitName(index)})` }}</p>
        </li>
        <li>
          <h6>
            {{
              checkPage == '입고식별표'
                ? '구매처'
                : '생산식별표'
                ? '납품처'
                : ''
            }}
          </h6>
          <p>{{ item.company_name }}</p>
        </li>
        <li>
          <h6>
            {{
              checkPage == '입고식별표'
                ? '납품일'
                : '생산식별표'
                ? '생산일'
                : ''
            }}
          </h6>
          <p>{{ item.input_date }}</p>
        </li>
        <li>
          <h6>바코드</h6>
          <ul>
            <li>
              <p class="barcode">
                {{ `*${item.barcode_num}*` }}
              </p>
            </li>
            <li>
              <p class="barcode_number">
                {{ $makeNumber(`${item.barcode_num}`) }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { getExpireDate, yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  props: ['printList', 'checkPage'],
  data() {
    return {
      // app: document.querySelector('#app'),
    };
  },
  computed: {
    ...mapGetters({
      Product: 'getProduct',
      Material: 'getMaterial',
      UnitCodes: 'getUnitCodes',
      companys: 'getCompany',
      systemCompany: 'getSystemCompany',
    }),
  },
  methods: {
    getExpireDate(item) {
      const products = this.findInfoFromId(this.Product, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      let printContents;
      if (this.checkPage == '발주식별표') {
        printContents = document.querySelector('.barcode_modal .modal_body');
      } else {
        printContents = document.querySelector('.print_modal .modal_body');
      }
      const printDiv = document.createElement('div');
      printDiv.className = 'print_wrap';
      let printContentsClone = printContents.cloneNode(true);
      document.body.appendChild(printDiv);
      printDiv.appendChild(printContentsClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
    getUnitName(index) {
      let inputPrintTemp = [];
      if (this.checkPage == '입고식별표') {
        inputPrintTemp = this.printList[index].material_id;
      } else {
        inputPrintTemp = this.printList[index].product_id;
      }
      const findMId = this.findInfoFromId(
        this.checkPage == '입고식별표' ? this.Material : this.Product,
        inputPrintTemp,
      );
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.UnitCodes,
          this.checkPage == '입고식별표'
            ? findMId.incoming_unit_id
            : findMId.stock_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
  },
  async created() {
    this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    this.FETCH('FETCH_UNIT', '단위');
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: 8cm 5cm landscape !important;
    margin: 0mm !important;
  }
}
</style>
