<template>
  <div class="modalPopup print_modal order_print_modal" v-if="modalClose">
    <div class="modal_header">
      <h2 class="title">원자재 발주 현황</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li>
          <h6>입고 요청일</h6>
          {{ `${startDate} ~ ${endDate}` }}
        </li>
        <li v-show="checkOption">
          <h6>선택옵션:</h6>
          [입고대기 항목]
        </li>
      </ul>
    </div>
    <div class="modal_body"></div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  props: ['startDate', 'endDate', 'checkOption'],
  data() {
    return {
      modalClose: true,
    };
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const orderHeader = document.querySelector(
        '.order_print_modal .modal_header',
      );
      const orderTable = document.querySelector(
        '.order_print_modal .modal_body .mes_tbl_wrap',
      );
      const printDiv = document.createElement('div');
      let orderHeaderClone = orderHeader.cloneNode(true);
      let orderTableClone = orderTable.cloneNode(true);

      printDiv.className = 'order_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(orderHeaderClone, orderTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  mounted() {
    let orderModal = document.querySelector('.order_print_modal .modal_body');
    let orderTable = document.querySelector('.order_print_table');
    let orderTableClone = orderTable.cloneNode(true);
    orderModal.appendChild(orderTableClone);
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 portrait !important;
    margin: auto 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
